import React, { useState } from 'react';
import SiteSearch from '../UI/SiteSearch/SiteSearch';
import Alert from '../UI/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import RequestService from '../../API/RequestService';
import alertsConfig from '../../config/alerts';
import objIsEmpty from '../../API/objIsEmpty';
import './Home.css';
import './../../styles/verifPreloader.css';

const Home = () => {
    let [siteSearch, setSiteSeacrh] = useState('');

    let [alertLeft, setAlertLeft] = useState('-2000px');
    let [alertText, setAlertText] = useState('');

    let [requesting, setRequesting] = useState(false);
    let [inputDisabled, setInputDisabled] = useState(false);

    let navigate = useNavigate();

    let errAlertStyles = {
        position: 'fixed',
        top: alertsConfig.topOffset,
        left: alertLeft
    };

    function showErrAlert() {
        setAlertLeft(alertsConfig.leftOffset);
        
        setTimeout(() => {
            setAlertLeft('-2000px');
            setInputDisabled(false);
        }, alertsConfig.showDuration);
    }

    function validate() {
        let validComplete = true;
        const urlRE = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

        if (siteSearch.trim() === '' || !urlRE.test(siteSearch)) {
            validComplete = false;
            setAlertText('Error: type correct site link');
            showErrAlert();
        }

        return validComplete;
    }

    async function searchWebsites() {
        if (validate()) {
            let payload = {
                search: siteSearch
            };
    
            setRequesting(true);
            setInputDisabled(true);
    
            try {
                let resp = await RequestService.post('api/search/site', {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }, payload);
        
                setRequesting(false);
        
                if (resp.status === 'success') {
                    if (objIsEmpty(resp.data.site)) {
                        setAlertText('This website wasn\'t verificated');
                        showErrAlert();
                    } else {
                        setInputDisabled(false);
                        navigate(`/site-info/${resp.data.site.id}`);
                    }
                } else {
                    setAlertText('Error occured');
                    showErrAlert();
                }
            } catch (err) {
                setRequesting(false);
                setAlertText('Server error');
                showErrAlert();
            }
        }
    }

    return (
        <div className="home">
            <div className="content-wrapper content-pads">
                <h1 className="home__title">
                    Make a website check
                </h1>

                <div className="home-preloader preloader-wrap" style={{ visibility: requesting ? 'visible' : 'hidden' }}>
                    <div className="transition-loader">
                        <div className="transition-loader-inner">
                            <label></label>
                            <label></label>
                            <label></label>
                            <label></label>
                            <label></label>
                            <label></label>
                        </div>
                    </div>
                </div>

                <div className="home__search">
                    <SiteSearch searchVal={siteSearch} 
                                searchValChange={setSiteSeacrh} 
                                enterPressed={searchWebsites}
                                isDisabled={inputDisabled}
                    />
                </div>

                <div className="home__descr">
                    <p>
                        This site is a complex resource checks for technical condition, seo optimization, usability, errors in the 
                        content part of the site, tracking a trademark of mass in comparison with competitors, 
                        such as analysis of advertising courses based on user behavior when clicking on the advertising.
                    </p>
                </div>
            </div>

            <Alert type="error" text={alertText} styles={errAlertStyles} />
        </div>
    );
};

export default Home;