import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import TokenStorage from '../../../API/TokenStorage';
import './ProfileButton.css';
import profileIcon from './../../../images/icons/profile.svg';
import optionsIcon from './../../../images/icons/options.svg';
import search from './../../../images/icons/search-white.svg';
import listIcon from './../../../images/icons/list.svg';
import logoutIcon from './../../../images/icons/logout.svg';

const ProfileButton = ({ styles }) => {
    let [dropDownVisible, setDropdownVisible] = useState(false);

    function dropDownToggle() {
        setDropdownVisible(!dropDownVisible);
    }

    function hideDropDown() {
        setDropdownVisible(false);
    }

    function clickNotOnDropDown(evt) {
        if (evt.target.closest('.profile-btn')) {
            return;
        } else if (!evt.target.closest('.profile-btn__dropdown')) {
            hideDropDown();
        }
    }

    function logoutHandle() {
        setTimeout(() => {
            TokenStorage.logout();
        });
    }

    useEffect(() => {
        document.addEventListener('click', clickNotOnDropDown);
        return () => {
            document.removeEventListener('click', clickNotOnDropDown);
        };
    }, []);

    return (
        <div className="profile-btn-wrap" style={{...styles}}>
            <button className="profile-btn" onClick={dropDownToggle}>
                <img src={profileIcon} alt="profile icon" />
            </button>

            <div className="profile-btn__dropdown" 
                 style={{display: dropDownVisible ? 'block' : 'none'}}
                 onClick={hideDropDown}
            >
                <Link to="/profile" className="profile-btn__link">
                    <img src={optionsIcon} alt="" />
                    <span className="section-name">Options</span>
                </Link>
                <Link to="/verification" className="profile-btn__link">
                    <img src={search} alt="" />
                    <span className="section-name">Verification</span>
                </Link>
                <Link to="/site-list" className="profile-btn__link">
                    <img src={listIcon} alt="" />
                    <span className="section-name">My websites</span>
                </Link>
                <button className="profile-btn__link  profile-btn__logout" onClick={logoutHandle}>
                    <img src={logoutIcon} alt="" />
                    <span className="section-name">Logout</span>
                </button>
            </div>
        </div>
    );
};

export default ProfileButton;