import navigateToHome from "./navigateToHome";

export default class TokenStorage {
    static getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    static getRefreshToken() {
        return localStorage.getItem('refreshToken');
    }

    static getTokenExpiresIn() {
        return localStorage.getItem('tokenExpiresIn');
    }

    static getTokenReceived() {
        return localStorage.getItem('tokenReceived');
    }

    static setAccessToken(newToken) {
        localStorage.setItem('accessToken', newToken);
    }

    static setRefreshToken(newToken) {
        localStorage.setItem('refreshToken', newToken);
    }

    static setTokenExpiresIn(expiresIn) {
        localStorage.setItem('tokenExpiresIn', expiresIn);
    }

    static setTokenReceived(received) {
        localStorage.setItem('tokenReceived', received);
    }

    static logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenExpiresIn');
        localStorage.removeItem('tokenReceived');
        navigateToHome();
    }
}