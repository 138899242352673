import config from '../config/config';
import TokenStorage from './TokenStorage';

export default class RequestService {

    static encodePayload(payload) {
        let encoded = [];

        for (let key in payload) {
            encoded.push(`${key}=${encodeURIComponent(payload[key])}`);
        }

        return encoded.join('&');
    }

    static isTokenFresh() {
        let now = Date.now() / 1000;
        let tokenExpires = parseInt(TokenStorage.getTokenExpiresIn());

        return now - TokenStorage.getTokenReceived() < tokenExpires;
    }

    static async refreshToken() {
        let resp = await fetch(`${config.backendDomain}/api/auth/refresh`, {
            headers: {
                'Authorization': `Bearer ${TokenStorage.getRefreshToken()}`
            },
            method: 'POST'
        });

        let json = await resp.json();

        if (json.status === 'success') {
            let tokens = json.data;
            TokenStorage.setAccessToken(tokens.access_token);
            TokenStorage.setRefreshToken(tokens.refresh_token);
            TokenStorage.setTokenExpiresIn(tokens.expires_in);
            TokenStorage.setTokenReceived(Date.now() / 1000);
        }
    }

    static async postRequest(endpoint, headers, payload) {
        let encodedPayload = this.encodePayload(payload);

        let resp = await fetch(`${config.backendDomain}/${endpoint}`, {
            method: 'POST',
            headers: headers,
            body: encodedPayload
        });

        let jsonData = await resp.json();

        return jsonData;
    }

    static async getRequest(endpoint, headers, signal) {
        let resp = await fetch(`${config.backendDomain}/${endpoint}`, {
            method: 'GET',
            headers: headers,
            signal
        });

        let jsonData = await resp.json();

        return jsonData;
    }

    static async putRequest(endpoint, headers, payload) {
        let data = new FormData();

        for (let key in payload) {
            data.append(key, payload[key]);
        }

        let resp = await fetch(`${config.backendDomain}/${endpoint}`, {
            method: 'PUT',
            headers: headers,
            body: data
        });

        let jsonData = await resp.json();

        return jsonData;
    }

    static async post(endpoint, headers, payload) {
        if (TokenStorage.getAccessToken() && !this.isTokenFresh()) {
            await this.refreshToken();

            if ('Authorization' in headers) {
                delete headers['Authorization'];
                headers['Authorization'] = `Bearer ${TokenStorage.getAccessToken()}`;
            }
        } 

        return this.postRequest(endpoint, headers, payload);
    }

    static async get(endpoint, headers, signal) {
        if (TokenStorage.getAccessToken() && !this.isTokenFresh()) {
            await this.refreshToken();

            if ('Authorization' in headers) {
                delete headers['Authorization'];
                headers['Authorization'] = `Bearer ${TokenStorage.getAccessToken()}`;
            }
        } 

        return this.getRequest(endpoint, headers, signal);
    }

    static async put(endpoint, headers, payload) {
        if (TokenStorage.getAccessToken() && !this.isTokenFresh()) {
            await this.refreshToken();

            if ('Authorization' in headers) {
                delete headers['Authorization'];
                headers['Authorization'] = `Bearer ${TokenStorage.getAccessToken()}`;
            }
        } 

        return this.putRequest(endpoint, headers, payload);
    }
}