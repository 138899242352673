import React, { useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ProfileButton from '../UI/ProfileButton/ProfileButton';
import AuthorizationContext from '../../contexts/AuthorizationContext';
import './Header.css';
import authIcon from './../../images/icons/auth.svg';
import testLogo from './../../images/testLogo.png';

const Header = ({ setHeaderHeight }) => {
    let header = useRef();
    let authContext = useContext(AuthorizationContext);

    useEffect(() => {
        setHeaderHeight(header.current.clientHeight);
    }, [header]);

    return (
        <header className="main-header" ref={header}>
            <div className="content-wrapper">
                <div className="main-header__content">
                    <div className="main-header__logo">
                        <Link to="/">
                            <img src={testLogo} alt="" />
                        </Link>
                    </div>

                    <div className="main-header__panel">
                        {
                            authContext.isAuthenticated ?
                                <ProfileButton />
                                :
                                <Link to="/signup">
                                    <div className="main-header__auth">
                                        <span>Sign Up</span>
                                        <img src={authIcon} alt="authentification icon" />
                                    </div>
                                </Link>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;