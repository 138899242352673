import React from 'react';
import { Outlet } from 'react-router-dom';

const Main = () => (
    <main className="main-content">
        <Outlet />
    </main> 
);

export default Main;
