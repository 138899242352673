import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = ({ setFooterHeight }) => {
    let footer = useRef();

    useEffect(() => {
        setFooterHeight(footer.current.offsetHeight);
    }, [footer]);

    return (
        <footer className="main-footer" ref={footer}>
            <div className="content-wrapper">

                <nav className="main-footer__nav">
                    <ul className="main-footer__nav-list">
                        <li>
                            <Link to="/" className="link">Home</Link>
                        </li>
                        <li>
                            <Link to="/verification" className="link">Website verification</Link>
                        </li>
                        <li>
                            <Link to="/site-list" className="link">My websites</Link>
                        </li>
                        <li>
                            <Link to="/contact" className="link">Contact us</Link>
                        </li>
                        <li>
                            <Link to="/politics" className="link">Politics</Link>
                        </li>
                    </ul>
                </nav>

                <div className="main-footer__copyright">
                    All rights reserved {(new Date).getFullYear()} ©
                </div>

            </div>
        </footer>
    );
};

export default Footer;