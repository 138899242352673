import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import TokenStorage from './API/TokenStorage';
import { useRoutes } from 'react-router-dom';
import { publicRoutes, privateRoutes } from './routes';
import AuthorizationContext from './contexts/AuthorizationContext';
import logoutUnauthorized from './API/logoutUnauthorized';
import './styles/fonts.css';
import './styles/reset.css';
import './styles/App.css';

function App() {
  let [headerHeight, setHeaderHeight] = useState(0);
  let [footerHeight, setFooterHeight] = useState(0);
  let isAuthenticated;

  if (TokenStorage.getAccessToken()) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }

  let appRoutes = useRoutes(isAuthenticated ? privateRoutes : publicRoutes);
  logoutUnauthorized();

  return (
    <AuthorizationContext.Provider value={{ isAuthenticated }}>
      <div className="App">
        <Header setHeaderHeight={setHeaderHeight} />

        <div className="main-content-wrap" style={{ minHeight: `calc(100vh - (${headerHeight}px + ${footerHeight}px))` }}>
          {appRoutes}
        </div>

        <Footer setFooterHeight={setFooterHeight} />
      </div>
    </AuthorizationContext.Provider>
  );
}

export default App;
