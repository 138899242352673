import RequestService from "./RequestService";
import TokenStorage from "./TokenStorage";

export default async function() {
    if (TokenStorage.getAccessToken()) {
        let abortCont = new AbortController();
        let timeoutId = setTimeout(() => abortCont.abort(), 8000);

        try {
            let resp = await RequestService.get('api/user', {
                'Authorization': `Bearer ${TokenStorage.getAccessToken()}`
            }, abortCont.signal);
    
            if (resp.status !== 'success') {
                TokenStorage.logout();
            }
        } catch(err) {
            TokenStorage.logout();
        } finally {
            clearTimeout(timeoutId);
        }
    }
}
