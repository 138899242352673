import React, { useEffect, useState } from 'react';
import cl from './Alert.module.css';

const Alert = React.forwardRef((props, ref) => {
    // -----
    // the type prop sets the alert window styles
    // possible values:
    // error - red window
    // success - green window
    // -----

    let [alertTypeClass, setAlertTypeClass] = useState('');

    useEffect(() => {
        switch (props.type) {
            case 'error':
                setAlertTypeClass(cl.alertError);
                break;
            case 'success':
                setAlertTypeClass(cl.alertSuccess);
                break;
        }
    }, [props.type]);

    return (
        <div className={`${cl.alert} ${alertTypeClass}`} style={{...props.styles}} ref={ref}>
            <div className={cl.alertText}>
                {props.text}
            </div>
        </div>
    );
});

export default Alert;