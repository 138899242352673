import React from 'react';
import './SiteSearch.css';
import searchIcon from './../../../images/icons/search-dark.svg';

const SiteSearch = ({ searchVal, searchValChange, enterPressed, isDisabled = false, styles = {} }) => {

    function keyPressedOnInput(evt) {
        if (evt.keyCode === 13) {
            enterPressed();
        }
    }

    return (
        <div className="site-search" style={{...styles}}>
            <input type="text" 
                   className="site-search__input site-input" 
                   placeholder="Resource URL" 
                   style={{ borderRadius: '16px', backgroundColor: 'rgba(255,255,255,0.5)' }}
                   value={searchVal}
                   onInput={(evt) => searchValChange(evt.target.value)}
                   onKeyDown={keyPressedOnInput}
                   disabled={isDisabled}
            />
            <img src={searchIcon} alt="search icon" className="site-search__icon" />
        </div>
    );
};

export default SiteSearch;