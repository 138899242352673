import Main from "./components/Main/Main";
import Home from "./components/Home/Home";
import loadable from '@loadable/component';

// ----- component-wrappers for lazy loading ------
const Registration = loadable(() => import('./components/Registration/Registration'));
const SignIn = loadable(() => import('./components/SignIn/SignIn'));
const ForgotPassword = loadable(() => import('./components/ForgotPassword/ForgotPassword'));
const PasswordReset = loadable(() => import('./components/ForgotPassword/PasswordReset'));
const Verification = loadable(() => import('./components/Verification/Verification'));

// const VerificationStart = loadable(() => import('./components/Verification/VerificationStart/VerificationStart'));
// const VerificationCode = loadable(() => import('./components/Verification/VerificationCode/VerificationCode'));
// const VerificationScreen = loadable(() => import('./components/Verification/VerificationScreen/VerificationScreen'));
// const VerificationDone = loadable(() => import('./components/Verification/VerificationDone/VerificationDone'));
// const VerificationError = loadable(() => import('./components/Verification/VerificationError/VerificationError'));

const SiteList = loadable(() => import('./components/SiteList/SiteList'));
const SiteInfo = loadable(() => import('./components/SiteInfo/SiteInfo'));
const UserProfile = loadable(() => import('./components/UserProfileComponent/UserProfile/UserProfile'));
const UserAgreement = loadable(() => import('./components/UserAgreement/UserAgreement'));
const ContactUs = loadable(() => import('./components/ContactUs/ContactUs'));
const NotFoundPage = loadable(() => import('./components/NotFoundPage/NotFoundPage'));

const commonRoutes = [
    { path: '/', element: <Home /> },
    { path: 'verification', element: <Verification /> },
    { path: 'site-list', element: <SiteList /> },
    { path: 'site-info/:id', element: <SiteInfo /> },
    { path: 'politics', element: <UserAgreement /> },
    { path: 'contact', element: <ContactUs /> },
    { path: '*', element: <NotFoundPage /> }
];

export const publicRoutes = [
    {
        path: '/',
        element: <Main />,
        children: [
            { path: 'signin', element: <SignIn /> },
            { path: 'signup', element: <Registration /> },
            { path: 'forgot_passw', element: <ForgotPassword /> },
            { path: 'passw_reset/:token', element: <PasswordReset /> },
            ...commonRoutes
        ]
    }
];

export const privateRoutes = [
    {
        path: '/',
        element: <Main />,
        children: [
            // { path: 'verification_code', element: <VerificationCode /> },
            // { path: 'verification', element: <VerificationScreen /> },
            // { path: 'verification_done', element: <VerificationDone /> },
            // { path: 'verification_err', element: <VerificationError /> },
            { path: 'profile', element: <UserProfile /> },
            ...commonRoutes
        ]
    }
];
